import { getData } from "./api";
import { useEffect, useState } from "react";
import { parseBackendResponse } from "../shared/util";

export const useAPIGet = <T>(
  url: string,
  params?: { [key: string]: string | number | boolean }
) => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState(null);

  const fetchData = () => {
    getData(url, params)
      .then(async (response) => {
        return await parseBackendResponse<T>(response);
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [url]);

  const refreshData = () => {
    fetchData();
  };

  return { error, data, refreshData };
};
