/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import React from "react";
import gaugeBG from "./gaugeBG.png";
import gaugeNeedle from "./gaugeNeedle.png";
import Gauge from "../guage/gauge";

interface Props {
  // messages: SystemMessage[];
  gauge: any;
  levels: any[];
}

export default function TipOfSpear(props: Props): JSX.Element {
  if (!props.gauge || !props.levels) return null;

  console.log("TipOfSpear", props.gauge);
  const ytd = props.gauge?.ytd || 0;

  const gaugeLevel = props.levels.filter((level: any) => {
    return level.showAmount > ytd * 1000;
  });

  const calcYTD = (total: number) => {
    if (total < 50) {
      total = total * 1000;
      return total;
    } else if (total > 999) {
      total = total / 1000;
      return total;
    }
    return total;
  };

  let gaugePercent = calcYTD(gaugeLevel.length === 0 ? ytd : gaugeLevel[0].showAmount / 1000);
  if (gaugeLevel.length > 0) {
    gaugePercent = ytd / gaugePercent;
  }

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={2} px={2} lineHeight={0.5}>
        <MDTypography variant="h6" fontWeight="medium">
          Tip of the Spear
        </MDTypography>
      </MDBox>
      <MDBox p={0} height={300} overflow={"auto"}>
        <Gauge value={gaugePercent} />
      </MDBox>
      <MDBox m={1} />
    </Card>
  );
}
