import DataTable from "components/DataTable";
import { DashboardWrapper } from "layouts/DashboardWrapper";
import { CameraMediaListType } from "./types";
import MDButton from "../../components/MDButton";
import React from "react";
import Icon from "@mui/material/Icon";
import { useNavigate, useParams } from "react-router-dom";
import { useAPIGet } from "../../api/useAPIGet";
import { zuluToHuman } from "../../shared/dateutils";
import { useUserPickerModal } from "../LMS/modals/LMSUserPicker";
import { postData } from "../../api/api";
import { parseBackendResponse } from "../../shared/util";
import { ClientEvent } from "clientevent";
import { CameraMediaPlayer, useCameraMediaPlayer } from "./modals/CameraMediaPlayer";
import MDBox from "../../components/MDBox";

export function MediaList() {
  const { uuid } = useParams<{ uuid: string }>();
  const { show: userPickerShow } = useUserPickerModal();
  const { show: useMediaPlayer } = useCameraMediaPlayer();

  const { data, refreshData } = useAPIGet<{ mediaList: CameraMediaListType[] }>(`camera/media`);
  const navigate = useNavigate();

  const updateCamera = (
    cameraId: string,
    dated: number,
    data: Partial<{ lmsId: number; notes: string }>
  ) => {
    postData(`camera/media/${cameraId}/${dated}/update`, data)
      .then(parseBackendResponse)
      .then((data) => {
        refreshData();
      })
      .catch((err) => {
        console.log("error", err);
        ClientEvent.emit("SNACKBAR", err.message || err);
      });
  };

  return (
    <DashboardWrapper>
      <DataTable<CameraMediaListType>
        // isSorted={true}
        endHeader={
          <MDBox ml={2}>
            <MDButton iconOnly={true} variant="gradient" color="dark" onClick={() => refreshData()}>
              <Icon title={"Refresh"}>refresh</Icon>
            </MDButton>
          </MDBox>
        }
        canSearch={true}
        table={{
          columns: [
            {
              Header: "Camera/Type",
              accessor: "display",
              width: "20%",
              Cell: ({ cell: { value, row } }) => (
                <>
                  {value} {row.original.type}
                </>
              ),
            },
            {
              Header: "Date/Status",
              accessor: "date_time",
              width: "10%",
              Cell: ({ cell: { value, row } }) => (
                <>
                  {zuluToHuman(String(value))} {row.original.status}
                </>
              ),
            },
            {
              Header: "LMS User/Notes",
              accessor: "notes",
              width: "30%",
              Cell: ({ cell: { value, row } }) => <>{row.original.lms_name || value}</>,
            },
            {
              Header: "Actions",
              accessor: "id",
              width: "20%",
              align: "right",
              disableSortBy: true,
              Cell: ({ cell: { value, row } }) => (
                <>
                  {row.original.status === "processed" && (
                    <MDButton
                      iconOnly={true}
                      variant="gradient"
                      color="info"
                      title={"View"}
                      onClick={() => {
                        useMediaPlayer({
                          camera_id: row.original.camera_id,
                          dated: row.original.dated,
                        });
                      }}
                    >
                      <Icon>movie</Icon>
                    </MDButton>
                  )}
                  <MDButton
                    iconOnly={true}
                    variant="gradient"
                    color="warning"
                    title={"Re-Process Video"}
                    onClick={() => console.log("clicked")}
                  >
                    <Icon>repeat</Icon>
                  </MDButton>
                  <MDButton
                    iconOnly={true}
                    variant="gradient"
                    color="dark"
                    title={"Link LMS"}
                    onClick={() => {
                      userPickerShow({}, (user) => {
                        updateCamera(row.original.camera_id, row.original.dated, {
                          lmsId: user.id,
                        });
                      });
                    }}
                  >
                    <Icon>contacts</Icon>
                  </MDButton>
                </>
              ),
            },
          ],
          rows: data?.mediaList || [],
        }}
      />
      <CameraMediaPlayer />
    </DashboardWrapper>
  );
}
