/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { FC, forwardRef, ReactNode } from "react";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// custom styles for the DefaultItem
import defaultItemIconBox from "examples/Items/DefaultItem/styles";

// Declaring props types for DefaultItem
interface Props {
  color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark";
  icon: ReactNode;
  title: string;
  description: string;

  endingContent?: ReactNode | null;

  [key: string]: any;
}

const CalendarItem: FC<Props> = forwardRef(
  ({ color, icon, title, description, endingContent, ...rest }, ref) => (
    <MDBox {...rest} ref={ref} display="flex" alignItems="center" justifyContent={"space-between"}>
      <>
        <MDBox ml={2} mt={0.5} lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium" fontSize={12}>
            {title}
          </MDTypography>
          <MDTypography variant="button" fontWeight="regular" color="text" fontSize={12}>
            {description}
          </MDTypography>
        </MDBox>
        {endingContent === undefined ? (
          <MDBox mr={0} sx={(theme) => defaultItemIconBox(theme, { color, width: 36, height: 36 })}>
            <Icon fontSize={"small"}>{icon}</Icon>
          </MDBox>
        ) : (
          <>{endingContent}</>
        )}
      </>
    </MDBox>
  )
);

// Declaring default props for DefaultItem
CalendarItem.defaultProps = {
  color: "info",
};

export default CalendarItem;
