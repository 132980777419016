/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

/**
 All of the routes for the Material Dashboard 2 PRO React are added here,
 You can add a new route, customize the routes and delete the routes here.

 Once you add a new route on this file it will be visible automatically on
 the Sidenav.

 For adding a new route you can follow the existing routes in the routes array.
 1. The `type` key with the `collapse` value is used for a route.
 2. The `type` key with the `title` value is used for a title inside the Sidenav.
 3. The `type` key with the `divider` value is used for a divider between Sidenav items.
 4. The `name` key is used for the name of the route on the Sidenav.
 5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
 6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
 7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
 inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
 8. The `route` key is used to store the route location which is used for the react router.
 9. The `href` key is used to store the external links location.
 10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
 10. The `component` key is used to store the component of its route.
 */

// Material Dashboard 2 PRO React layouts
// import Analytics from "layouts/dashboards/analytics";
// import Sales from "layouts/dashboards/sales";
import ProfileOverview from "layouts/pages/profile/profile-overview";

// Material Dashboard 2 PRO React TS components
// @mui icons
import Icon from "@mui/material/Icon";

// Images
import { Logout } from "./pages/Dashboard/Logout";
import { Home } from "./pages/Dashboard/Home";
import { Route } from "./interfaces/RouteTypes";
import { PermissionModel } from "./interfaces/permissions";
import { hasPermission } from "./shared/permissionutils";
import { Blank } from "./pages/Blank";
import { CameraList } from "./pages/Cameras/CameraList";
import { CameraRecordings } from "./pages/Cameras/CameraRecordings";
import { MediaList } from "./pages/Cameras/MediaList";
import { Calendar } from "./pages/Calendar/Calendar";
import { MyEmail } from "./pages/MyAccount/MyEmail";

export function filterByPerms(perms: PermissionModel): Route[] {
  let isDivider = true;
  const _filterByPerms = (routes: Route[]): Route[] => {
    return routes.filter((route: any) => {
      if (route.type === "divider" && isDivider) return false;
      if (route.type === "collapse" && route.collapse && route.collapse.length > 0) {
        route.collapse = _filterByPerms(route.collapse);
        if (route.collapse.length === 0) {
          route.noCollapse = true;
          delete route.collapse;
        }
      }
      if (
        route.type === "collapse" &&
        !route.href &&
        !route.route &&
        !route.component &&
        !route.collapse
      )
        return false;
      isDivider = route.type === "divider";
      return route.perms === undefined ? true : hasPermission(route.perms);
    });
  };
  return _filterByPerms(routes);
}

const routes: Route[] = [
  {
    type: "collapse",
    name: "My Account",
    key: "user-display",
    icon: <Icon fontSize="medium">person</Icon>,
    // perms: RequiredPermissions.CalendarSelfView,
    // icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    collapse: [
      // {
      //   name: "My Profile",
      //   key: "my-profile",
      //   // perms: RequiredPermissions.CalendarSelfView,
      //   route: "/pages/profile/profile-overview",
      //   component: <ProfileOverview/>,
      // },
      {
        name: "My Email",
        key: "my-email",
        // perms: RequiredPermissions.CalendarSelfView,
        route: "/myemail",
        component: <MyEmail />,
      },
      // {
      //   name: "Settings",
      //   key: "profile-settings",
      //   perms: RequiredPermissions.CalendarSelfView,
      //   route: "/pages/account/settings",
      //   component: <Settings/>,
      // },
      {
        name: "Logout",
        // perms: RequiredPermissions.CalendarSelfView,
        key: "logout",
        route: "/logout",
        component: <Logout />,
      },
    ],
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    noCollapse: true,
    name: "Home",
    key: "home",
    route: "/home",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    component: <Home />,
    // collapse: [
    //   {
    //     name: "Analytics",
    //     key: "analytics",
    //     route: "/dashboards/analytics",
    //     component: <Analytics />,
    //   },
    //   {
    //     name: "Sales",
    //     key: "sales",
    //     route: "/dashboards/sales",
    //     component: <Sales />,
    //   },
    // ],
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Applications",
    key: "applications",
    route: "/applications",
    icon: <Icon fontSize="medium">assignment</Icon>,
    component: <Blank />,
    perms: [{ admin: { applications: [1] } }],
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Briefcase",
    key: "briefcase",
    route: "/briefcase",
    icon: <Icon fontSize="medium">work</Icon>,
    component: <Blank />,
    perms: [{ admin: { briefcase: [1] } }],
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Calendar",
    key: "calendar",
    route: "/calendar",
    icon: <Icon fontSize="medium">calendar_month</Icon>,
    component: <Calendar />,
    subRoutes: [{ key: "calendar-user", route: "/calendar/:cal_id", component: <Calendar /> }],
    perms: [{ admin: { calendar: [1] } }],
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Cameras",
    key: "cameras",
    route: "/cameras",
    icon: <Icon fontSize="medium">camera_indoor</Icon>,
    component: <CameraList />,
    perms: [{ admin: { calendar: [1] } }],
    subRoutes: [
      { key: "cameras-all-media", route: "/cameras/allmedia", component: <MediaList /> },
      { key: "camera-media", route: "/cameras/:id/media", component: <CameraRecordings /> },
    ],
  },
  {
    type: "collapse",
    name: "Careers",
    key: "careers",
    route: "/careers",
    icon: <Icon fontSize="medium">manage_account</Icon>,
    perms: [{ admin: { careers: [1] } }, { admin: { careers: [2] } }, { admin: { careers: [3] } }],
    collapse: [
      {
        name: "Applicants",
        key: "applicants",
        route: "/careers/applicants",
        perms: [{ admin: { careers: [1] } }, { admin: { careers: [2] } }],
        component: <Blank />,
      },
      {
        name: "Access",
        key: "access",
        route: "/careers/access",
        perms: [{ admin: { careers: [2] } }, { admin: { careers: [3] } }],
        component: <Blank />,
      },
    ],
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Debt Calculator",
    key: "debtcalc",
    route: "/debtcalc",
    icon: <Icon fontSize="medium">calculate</Icon>,
    component: <Blank />,
    perms: [{ admin: { debt: [1] } }],
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Events",
    key: "events",
    route: "/events",
    icon: <Icon fontSize="medium">events</Icon>,
    component: <Blank />,
    perms: [{ admin: { events: [1] } }],
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Marketing Manager",
    key: "leads",
    route: "/leads",
    icon: <Icon fontSize="medium">contacts</Icon>,
    component: <Blank />,
    perms: [{ admin: { lms: [1] } }],
  },
  {
    type: "collapse",
    name: "Lookup",
    key: "lookup",
    route: "/lookup",
    icon: <Icon fontSize="medium">search</Icon>,
    perms: [{ admin: { lms: [1] } }, { admin: { intakes: [1] } }],
    collapse: [
      {
        name: "Intakes",
        key: "intakes",
        route: "/lookup/intakes",
        perms: [{ admin: { intakes: [1] } }],
        component: <Blank />,
      },
      {
        name: "Leads",
        key: "leads",
        route: "/lookup/leads",
        perms: [{ admin: { lms: [1] } }],
        component: <Blank />,
      },
      {
        name: "Policy Data",
        key: "policies",
        route: "/lookup/policies",
        perms: [{ admin: { lms: [1] } }],
        component: <Blank />,
      },
    ],
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Store",
    key: "store",
    route: "/store",
    icon: <Icon fontSize="medium">store_front</Icon>,
    component: <Blank />,
    perms: [{ admin: { store: [1] } }],
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Resource Center",
    key: "resources",
    route: "/resources",
    icon: <Icon fontSize="medium">history_edu</Icon>,
    component: <Blank />,
    perms: [{ admin: { resources: [1] } }],
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Review Center",
    key: "reviews",
    route: "/reviews",
    icon: <Icon fontSize="medium">reviews</Icon>,
    component: <Blank />,
    perms: [{ admin: { reviews: [1] } }],
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Training Center",
    key: "training",
    route: "/training",
    icon: <Icon fontSize="medium">subscriptions</Icon>,
    component: <Blank />,
    perms: [{ admin: { training: [1] } }],
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Tax Center",
    key: "taxes",
    route: "/taxes",
    icon: <Icon fontSize="medium">account_balance</Icon>,
    component: <Blank />,
    perms: [{ admin: { taxes: [1] } }],
  },
  {
    type: "collapse",
    name: "Administration",
    key: "admin",
    route: "/admin",
    icon: <Icon fontSize="medium">admin_panel_settings</Icon>,
    perms: [
      { admin: { applications: [4] } },
      { admin: { users: [4] } },
      { admin: { splash: [2] } },
    ],
    collapse: [
      {
        name: "Banners",
        key: "banners",
        route: "/admin/banners",
        component: <Blank />,
      },
      {
        name: "Users",
        key: "users",
        route: "/admin/users",
        component: <Blank />,
      },
    ],
  },

  // {type: "title", title: "Pages", key: "title-pages"},
  // {
  //   type: "collapse",
  //   name: "Pages",
  //   key: "pages",
  //   perms: [{admin: {lms: [2]}}],
  //   icon: <Icon fontSize="medium">image</Icon>,
  //   collapse: [
  //     {
  //       name: "Profile",
  //       key: "profile",
  //       collapse: [
  //         {
  //           name: "Profile Overview",
  //           key: "profile-overview",
  //           route: "/pages/profile/profile-overview",
  //           component: <ProfileOverview/>,
  //         },
  //         {
  //           name: "All Projects",
  //           key: "all-projects",
  //           route: "/pages/profile/all-projects",
  //           component: <AllProjects/>,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Users",
  //       key: "users",
  //       collapse: [
  //         {
  //           name: "New User",
  //           key: "new-user",
  //           route: "/pages/users/new-user",
  //           component: <NewUser/>,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Account",
  //       key: "account",
  //       collapse: [
  //         {
  //           name: "Settings",
  //           key: "settings",
  //           route: "/pages/account/settings",
  //           component: <Settings/>,
  //         },
  //         {
  //           name: "Billing",
  //           key: "billing",
  //           route: "/pages/account/billing",
  //           component: <Billing/>,
  //         },
  //         {
  //           name: "Invoice",
  //           key: "invoice",
  //           route: "/pages/account/invoice",
  //           component: <Invoice/>,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Projects",
  //       key: "projects",
  //       collapse: [
  //         {
  //           name: "Timeline",
  //           key: "timeline",
  //           route: "/pages/projects/timeline",
  //           component: <Timeline/>,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Pricing Page",
  //       key: "pricing-page",
  //       route: "/pages/pricing-page",
  //       component: <PricingPage/>,
  //     },
  //     {name: "RTL", key: "rtl", route: "/pages/rtl", component: <RTL/>},
  //     {name: "Widgets", key: "widgets", route: "/pages/widgets", component: <Widgets/>},
  //     {name: "Charts", key: "charts", route: "/pages/charts", component: <Charts/>},
  //     {
  //       name: "Notfications",
  //       key: "notifications",
  //       route: "/pages/notifications",
  //       component: <Notifications/>,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Applications",
  //   key: "applications2",
  //   icon: <Icon fontSize="medium">apps</Icon>,
  //   collapse: [
  //     {
  //       name: "Kanban",
  //       key: "kanban",
  //       route: "/applications/kanban",
  //       component: <Kanban/>,
  //     },
  //     {
  //       name: "Wizard",
  //       key: "wizard",
  //       route: "/applications/wizard",
  //       component: <Wizard/>,
  //     },
  //     {
  //       name: "Data Tables",
  //       key: "data-tables",
  //       route: "/applications/data-tables",
  //       component: <DataTables/>,
  //     },
  //     {
  //       name: "Calendar",
  //       key: "calendar",
  //       route: "/applications/calendar",
  //       component: <Calendar/>,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Ecommerce",
  //   key: "ecommerce",
  //   icon: <Icon fontSize="medium">shopping_basket</Icon>,
  //   collapse: [
  //     {
  //       name: "Products",
  //       key: "products",
  //       collapse: [
  //         {
  //           name: "New Product",
  //           key: "new-product",
  //           route: "/ecommerce/products/new-product",
  //           component: <NewProduct/>,
  //         },
  //         {
  //           name: "Edit Product",
  //           key: "edit-product",
  //           route: "/ecommerce/products/edit-product",
  //           component: <EditProduct/>,
  //         },
  //         {
  //           name: "Product Page",
  //           key: "product-page",
  //           route: "/ecommerce/products/product-page",
  //           component: <ProductPage/>,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Orders",
  //       key: "orders",
  //       collapse: [
  //         {
  //           name: "Order List",
  //           key: "order-list",
  //           route: "/ecommerce/orders/order-list",
  //           component: <OrderList/>,
  //         },
  //         {
  //           name: "Order Details",
  //           key: "order-details",
  //           route: "/ecommerce/orders/order-details",
  //           component: <OrderDetails/>,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Authentication",
  //   key: "authentication",
  //   icon: <Icon fontSize="medium">content_paste</Icon>,
  //   collapse: [
  //     {
  //       name: "Sign In",
  //       key: "sign-in",
  //       collapse: [
  //         {
  //           name: "Basic",
  //           key: "basic",
  //           route: "/authentication/sign-in/basic",
  //           component: <SignInBasic/>,
  //         },
  //         {
  //           name: "Cover",
  //           key: "cover",
  //           route: "/authentication/sign-in/cover",
  //           component: <SignInCover/>,
  //         },
  //         {
  //           name: "Illustration",
  //           key: "illustration",
  //           route: "/authentication/sign-in/illustration",
  //           component: <SignInIllustration/>,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Sign Up",
  //       key: "sign-up",
  //       collapse: [
  //         {
  //           name: "Cover",
  //           key: "cover",
  //           route: "/authentication/sign-up/cover",
  //           component: <SignUpCover/>,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Reset Password",
  //       key: "reset-password",
  //       collapse: [
  //         {
  //           name: "Cover",
  //           key: "cover",
  //           route: "/authentication/reset-password/cover",
  //           component: <ResetCover/>,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {type: "divider", key: "divider-1"},
  // {type: "title", title: "Docs", key: "title-docs"},
  // {
  //   type: "collapse",
  //   name: "Basic",
  //   key: "basic",
  //   icon: <Icon fontSize="medium">upcoming</Icon>,
  //   collapse: [
  //     {
  //       name: "Getting Started",
  //       key: "getting-started",
  //       collapse: [
  //         {
  //           name: "Overview",
  //           key: "overview",
  //           href: "https://www.creative-tim.com/learning-lab/react/overview/material-dashboard/",
  //         },
  //         {
  //           name: "License",
  //           key: "license",
  //           href: "https://www.creative-tim.com/learning-lab/react/license/material-dashboard/",
  //         },
  //         {
  //           name: "Quick Start",
  //           key: "quick-start",
  //           href: "https://www.creative-tim.com/learning-lab/react/quick-start/material-dashboard/",
  //         },
  //         {
  //           name: "Build Tools",
  //           key: "build-tools",
  //           href: "https://www.creative-tim.com/learning-lab/react/build-tools/material-dashboard/",
  //         },
  //       ],
  //     },
  //     {
  //       name: "Foundation",
  //       key: "foundation",
  //       collapse: [
  //         {
  //           name: "Colors",
  //           key: "colors",
  //           href: "https://www.creative-tim.com/learning-lab/react/colors/material-dashboard/",
  //         },
  //         {
  //           name: "Grid",
  //           key: "grid",
  //           href: "https://www.creative-tim.com/learning-lab/react/grid/material-dashboard/",
  //         },
  //         {
  //           name: "Typography",
  //           key: "base-typography",
  //           href: "https://www.creative-tim.com/learning-lab/react/base-typography/material-dashboard/",
  //         },
  //         {
  //           name: "Borders",
  //           key: "borders",
  //           href: "https://www.creative-tim.com/learning-lab/react/borders/material-dashboard/",
  //         },
  //         {
  //           name: "Box Shadows",
  //           key: "box-shadows",
  //           href: "https://www.creative-tim.com/learning-lab/react/box-shadows/material-dashboard/",
  //         },
  //         {
  //           name: "Functions",
  //           key: "functions",
  //           href: "https://www.creative-tim.com/learning-lab/react/functions/material-dashboard/",
  //         },
  //         {
  //           name: "Routing System",
  //           key: "routing-system",
  //           href: "https://www.creative-tim.com/learning-lab/react/routing-system/material-dashboard/",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Components",
  //   key: "components",
  //   icon: <Icon fontSize="medium">view_in_ar</Icon>,
  //   collapse: [
  //     {
  //       name: "Alerts",
  //       key: "alerts",
  //       href: "https://www.creative-tim.com/learning-lab/react/alerts/material-dashboard/",
  //     },
  //     {
  //       name: "Avatar",
  //       key: "avatar",
  //       href: "https://www.creative-tim.com/learning-lab/react/avatar/material-dashboard/",
  //     },
  //     {
  //       name: "Badge",
  //       key: "badge",
  //       href: "https://www.creative-tim.com/learning-lab/react/badge/material-dashboard/",
  //     },
  //     {
  //       name: "Badge Dot",
  //       key: "badge-dot",
  //       href: "https://www.creative-tim.com/learning-lab/react/badge-dot/material-dashboard/",
  //     },
  //     {
  //       name: "Box",
  //       key: "box",
  //       href: "https://www.creative-tim.com/learning-lab/react/box/material-dashboard/",
  //     },
  //     {
  //       name: "Buttons",
  //       key: "buttons",
  //       href: "https://www.creative-tim.com/learning-lab/react/buttons/material-dashboard/",
  //     },
  //     {
  //       name: "Date Picker",
  //       key: "date-picker",
  //       href: "https://www.creative-tim.com/learning-lab/react/datepicker/material-dashboard/",
  //     },
  //     {
  //       name: "Dropzone",
  //       key: "dropzone",
  //       href: "https://www.creative-tim.com/learning-lab/react/dropzone/material-dashboard/",
  //     },
  //     {
  //       name: "Editor",
  //       key: "editor",
  //       href: "https://www.creative-tim.com/learning-lab/react/quill/material-dashboard/",
  //     },
  //     {
  //       name: "Input",
  //       key: "input",
  //       href: "https://www.creative-tim.com/learning-lab/react/input/material-dashboard/",
  //     },
  //     {
  //       name: "Pagination",
  //       key: "pagination",
  //       href: "https://www.creative-tim.com/learning-lab/react/pagination/material-dashboard/",
  //     },
  //     {
  //       name: "Progress",
  //       key: "progress",
  //       href: "https://www.creative-tim.com/learning-lab/react/progress/material-dashboard/",
  //     },
  //     {
  //       name: "Snackbar",
  //       key: "snackbar",
  //       href: "https://www.creative-tim.com/learning-lab/react/snackbar/material-dashboard/",
  //     },
  //     {
  //       name: "Social Button",
  //       key: "social-button",
  //       href: "https://www.creative-tim.com/learning-lab/react/social-buttons/material-dashboard/",
  //     },
  //     {
  //       name: "Typography",
  //       key: "typography",
  //       href: "https://www.creative-tim.com/learning-lab/react/typography/material-dashboard/",
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Change Log",
  //   key: "changelog",
  //   href: "https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md",
  //   icon: <Icon fontSize="medium">receipt_long</Icon>,
  //   noCollapse: true,
  // },
];

export default routes;
