import { ModalMaker } from "../../../components/ModalMaker/ModalMaker";
import MDButton from "../../../components/MDButton";
import React, { ChangeEvent, KeyboardEvent, useCallback, useEffect, useState } from "react";
import MDInput from "../../../components/MDInput";
import { getData } from "../../../api/api";
import { parseBackendResponse } from "../../../shared/util";
import { CalendarListData } from "../../Calendar/types";
import { LMSSearchEntryData } from "../types";
import MDBox from "components/MDBox";
import Switch from "@mui/material/Switch";
import MDTypography from "../../../components/MDTypography";
import { PageLists } from "../../../components/PageLists/PageLists";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

interface LMSStateData {
  calendarMenu: CalendarListData[];
  leads: LMSSearchEntryData[];
  limit: string;
  page: string;

  results: number;
}

interface ModalProps {
  q?: string;
  page?: number;
  limit?: number;
  all?: boolean;
}

type ModalCallback = (data: LMSSearchEntryData) => void;

export const useUserPickerModal = () => {
  return {
    show: (options: ModalProps = {}, callback: ModalCallback) => {
      if (translationLayer.show) {
        translationLayer.callback = callback;
        translationLayer.show(options);
      }
    },
  };
};

const translationLayer: {
  show: (options: ModalProps) => void | null;
  callback: ModalCallback | null;
} = {
  show: null,
  callback: null,
};

export function LMSUserPicker(props: {}) {
  const [open, setOpen] = useState(false);
  const [searchData, setSearchData] = useState<{
    q: string;
    page: number;
    limit: number;
    all?: boolean;
  }>({
    q: "",
    page: 1,
    limit: 15,
    all: false,
  });
  const [lmsData, setLMSData] = useState<LMSStateData>(null);

  useEffect(() => {
    translationLayer["show"] = (options: ModalProps = {}) => {
      setOpen(true);
      setSearchData((prev) => ({
        ...prev,
        q: options.q || "",
        page: options.page || 1,
        limit: options.limit || 15,
        all: options.all || false,
      }));
    };
    return () => {
      translationLayer["show"] = null;
      translationLayer["callback"] = null;
    };
  }, [setOpen, setSearchData]);

  const resetSearch = () => {
    setSearchData({
      q: "",
      page: 1,
      limit: 15,
      all: false,
    });
    setOpen(false);
    setLMSData(null);
  };

  const getLMSData = useCallback(() => {
    if (!searchData.q) return;
    getData("lms/leads", searchData)
      .then(async (response) => {
        return await parseBackendResponse<LMSStateData>(response);
      })
      .then((data) => {
        // console.log(data)
        setLMSData(data);
        setSearchData((prev) => ({ ...prev, limit: Number(data.limit), page: Number(data.page) }));
      })
      .catch((error) => {
        console.error(error);
      });
  }, [searchData]);

  return (
    <ModalMaker
      title={`Find User`}
      open={open}
      fullWidth
      actions={
        <>
          <MDBox
            p={0}
            mx={0}
            height={35}
            display={"flex"}
            alignContent={"baseline"}
            justifyContent={"space-between"}
          >
            {lmsData && (
              <PageLists
                start={0}
                end={Math.ceil(lmsData.results / searchData.limit)}
                onChange={(page: number) =>
                  setSearchData((prev) => ({
                    ...prev,
                    page: Number(page),
                  }))
                }
                currentPage={0}
                perPage={searchData.limit}
                totalEntries={lmsData.results}
              />
            )}
            {/*<MDButton color={"primary"}>Select</MDButton>*/}
            <MDButton color={"info"} onClick={resetSearch}>
              Close
            </MDButton>
          </MDBox>
        </>
      }
    >
      <MDBox
        p={2}
        mx={12}
        my={-5}
        display="flex"
        justifyContent="left"
        position={"fixed"}
        bgColor={"white"}
      >
        <MDInput
          label={"Search"}
          value={searchData.q}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setSearchData({ ...searchData, q: e.target.value })
          }
          onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") {
              getLMSData();
            }
          }}
        />
        <MDButton onClick={getLMSData} color={"secondary"}>
          Search
        </MDButton>

        <MDBox display="flex" alignItems="center" mb={0.5} ml={0}>
          <MDBox mt={0.5}>
            <Switch
              checked={searchData.all}
              onChange={() => setSearchData({ ...searchData, all: !searchData.all })}
            />
          </MDBox>
          <MDBox width="80%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Expand Results
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>

      <Table style={{ overflowY: "auto", height: "inherit", marginTop: 15 }}>
        <TableBody>
          {lmsData &&
            lmsData.leads.map((lead, index) => (
              <TableRow key={lead.id}>
                <TableCell>
                  {lead.rank} {lead.fname} {lead.lname}
                </TableCell>
                <TableCell>
                  {lead.address1}
                  <br />
                  {lead.city}, {lead.state} {lead.zip}
                </TableCell>
                <TableCell>
                  <MDButton
                    color={"info"}
                    onClick={() => {
                      translationLayer.callback && translationLayer.callback(lead);
                      resetSearch();
                    }}
                  >
                    Select
                  </MDButton>
                  <MDButton color={"secondary"}>View</MDButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </ModalMaker>
  );
}
