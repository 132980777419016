import { CalendarData } from "../types";
import { getAARStatus } from "../../../shared/AARConstants";
import moment from "moment";
import { forceSingleTimeZone, formatTimeFromEpochAFBN } from "../../../shared/dateutils";

const eventStyleGetter = (event: CalendarData) => {
  if (event.subStatus > 0) {
    const styleIt = getAARStatus(event.subStatus);
    event.eventColor = styleIt.bgColor || event.eventColor;
    event.color = styleIt.color || event.color;
  }
  return {
    backgroundColor: event.colorOverride || event.eventColor || "",
    textColor: event.color || "",
    // fontSize: 10,
    // zIndex: 100,
  };
};

export const calendarDataRenderer = (event: CalendarData) => {
  return {
    id: event.id,
    title: event.title,
    allDay:
      moment(forceSingleTimeZone(formatTimeFromEpochAFBN(event.starttime))).format("YYYY-MM-DD") !==
      moment(forceSingleTimeZone(formatTimeFromEpochAFBN(event.endtime))).format("YYYY-MM-DD"),
    start: moment(forceSingleTimeZone(formatTimeFromEpochAFBN(event.starttime))).format(
      "YYYY-MM-DDTHH:mm:ss"
    ),
    end: moment(forceSingleTimeZone(formatTimeFromEpochAFBN(event.endtime))).format(
      "YYYY-MM-DDTHH:mm:ss"
    ),
    ...eventStyleGetter(event),
    extendedProps: event,
  };
};
