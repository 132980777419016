import {
  alpha,
  Button,
  Divider,
  ListSubheader,
  ListSubheaderProps,
  Menu,
  MenuItem,
  MenuProps,
  styled,
} from "@mui/material";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import React from "react";

const InternalStyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export interface StyledDropdownMenuAction {
  type: "action" | "divider";
  display?: string;
  icon?: string;
}

export interface StyledDropdownMenuDivider {
  type: "divider";
}

export interface StyledDropdownMenuHeader {
  type: "header";
  display: string;
}

export type StyledDropdownMenuOption =
  | StyledDropdownMenuAction
  | StyledDropdownMenuDivider
  | StyledDropdownMenuHeader;

interface Props {
  baseButtons?: { [key: string]: StyledDropdownMenuAction };
  options: { [key: string]: StyledDropdownMenuOption };
  optionTitle?: string;
  id: string;
  onAction: (id: string, action: string) => void;
  optionIcon?: string;
  optionColor?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark";
}

function Subheader(props: ListSubheaderProps) {
  return <ListSubheader {...props} />;
}

Subheader.muiSkipListHighlight = true;

export function StyledDropdownMenu(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (action?: string) => () => {
    if (action) {
      props.onAction(props.id, action);
    }
    setAnchorEl(null);
  };

  return (
    <span>
      {props.baseButtons &&
        Object.keys(props.baseButtons).map((key) => {
          return (
            <Button
              key={key}
              id={`styled-dropdown-menu-button-${key}`}
              variant="contained"
              disableElevation
              // onClick={handleClick}
              // endIcon={<Icon>keyboard_arrow_down</Icon>}
              color={"info"}
            >
              Options
            </Button>
          );
        })}
      {props.options && Object.keys(props.options).length > 0 && (
        <>
          <MDButton
            iconOnly={true}
            variant="gradient"
            color={props.optionColor || "info"}
            title={props.optionTitle}
            onClick={handleClick}
          >
            <Icon>{props.optionIcon || "keyboard_arrow_down"}</Icon>
          </MDButton>
          {/*<Button*/}
          {/*  id="demo-customized-button"*/}
          {/*  aria-controls={open ? 'demo-customized-menu' : undefined}*/}
          {/*  aria-haspopup="true"*/}
          {/*  aria-expanded={open ? 'true' : undefined}*/}
          {/*  variant="contained"*/}
          {/*  disableElevation*/}
          {/*  onClick={handleClick}*/}
          {/*  endIcon={<Icon>keyboard_arrow_down</Icon>}*/}
          {/*>*/}
          {/*  Options*/}
          {/*</Button>*/}
          <InternalStyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose()}
          >
            {Object.keys(props.options).map((key, i) => {
              if (props.options[key].type === "divider") {
                return <Divider key={`menu-div-${i}`} sx={{ my: 0.5 }} />;
              } else if (props.options[key].type === "action") {
                let option = props.options[key] as unknown as StyledDropdownMenuAction;
                return (
                  <MenuItem key={`menu-item-${i}`} onClick={handleClose(key)} disableRipple>
                    {option.icon && <Icon>{option.icon}</Icon>}
                    {option.display}
                  </MenuItem>
                );
              } else if (props.options[key].type === "header") {
                let option = props.options[key] as unknown as StyledDropdownMenuHeader;
                return (
                  <Subheader
                    key={`menu-subheader-${i}`}
                    style={{ lineHeight: "unset", pointerEvents: "none" }}
                  >
                    {option.display}
                  </Subheader>
                );
              }
            })}
            {/*<MenuItem onClick={handleClose()} disableRipple>*/}
            {/*  <Icon>edit</Icon>*/}
            {/*  Edit*/}
            {/*</MenuItem>*/}
            {/*<MenuItem onClick={handleClose} disableRipple>*/}
            {/*  <Icon>file-copy</Icon>*/}
            {/*  Duplicate*/}
            {/*</MenuItem>*/}
            {/*<Divider sx={{my: 0.5}}/>*/}
            {/*<MenuItem onClick={handleClose} disableRipple>*/}
            {/*  <Icon>archive</Icon>*/}
            {/*  Archive*/}
            {/*</MenuItem>*/}
            {/*<MenuItem onClick={handleClose} disableRipple>*/}
            {/*  <Icon>more-horiz</Icon>*/}
            {/*  More*/}
            {/*</MenuItem>*/}
          </InternalStyledMenu>
        </>
      )}
    </span>
  );
}
