import {
  CameraCalendarDataType,
  CameraCommandOptions,
  CameraContolType,
  CameraDataControlType,
  CameraDataType,
} from "../types";
import React, { useCallback, useEffect, useMemo } from "react";
import io from "api/socket.io";
import { Socket } from "types/socket.io-client";

let socketURL = "https://picam.aws01.webtest.us/";

interface CameraDevice {
  id: string;
  version: string;
  status: string;
  last_response: string;
}

let canReconnect = false;
let openTerm = "";
export const useCameraControl = (cameras: (CameraDataType | CameraCalendarDataType)[]) => {
  const [socket, setSocket] = React.useState<Socket>(null);
  const [connected, setConnected] = React.useState<boolean>(false);
  const [_rc, forceReconnect] = React.useState<{}>({});
  const [sockDevice, setSockDevice] = React.useState<{ [key: string]: CameraContolType }>(null);

  useEffect(() => {
    if (socket && cameras && cameras.length > 0) {
      const manualReconnect = () => {
        // if (canReconnect) forceReconnect({});
        forceReconnect({});
      };
      // console.log(`Connecting to ${socketURL}`);
      let onConnect = () => {
        console.log("Connected to socket server");
        setConnected(true);
        socket.emit("request", {
          action: "login",
          type: "client",
          step: 0,
          loginId: 1,
          deviceIds: cameras.map((device) => device.id),
        });
      };
      let onDisconnect = () => {
        console.log("Disconnected from socket server");
        setConnected(false);
        manualReconnect();
      };

      let onRequest = (data: any) => {
        switch (data.action) {
          case "sethost":
            if (data["data"] && data["data"]["host"]) {
              socketURL = data["data"]["host"];
              forceReconnect({});
            }
            break;
        }
      };
      let onResponse = (data: any) => {
        // console.log(data);
        switch (data.action) {
          case "login":
            if (data.result === "Success") {
              socket.emit("request", { action: "devices" });
            }
            break;
          case "devices":
            data.data.map((device: any, index: number) => {
              // console.log(device);
              setSockDevice((prevState) => ({
                ...prevState,
                [device.deviceId]: {
                  status: device.status,
                  version: device.version,
                  last_response: device.lastTime,
                },
              }));
            });
            break;
          case "device":
            // console.log(data.data);
            setSockDevice((prevState) => ({
              ...prevState,
              [data.data.deviceId]: {
                status: data.data.status,
                version: data.data.version,
                last_response: data.data.lastTime,
              },
            }));
            break;
          case "preview":
            if (data.state !== "captured") return;
            setSockDevice((prevState) => ({
              ...prevState,
              [data.replyTo]: {
                ...prevState[data.replyTo],
                preview: data.data,
              },
            }));
            break;
        }
      };
      let onTerminalOut = () => {};

      socket.on("connect", onConnect);
      socket.on("disconnect", onDisconnect);
      socket.on("request", onRequest);
      socket.on("response", onResponse);
      socket.on("terminal-out", onTerminalOut);
      canReconnect = true;
      socket.connect();
      console.log("Socket called connected");
      return () => {
        console.log("Socket called disconnect");
        canReconnect = false;
        socket.off("connect", onConnect);
        socket.off("disconnect", onDisconnect);
        socket.off("request", onRequest);
        socket.off("response", onResponse);
        socket.off("terminal-out", onTerminalOut);
        socket.disconnect();
      };
    }
  }, [socket, cameras, _rc]);

  useEffect(() => {
    // if (!socket || !socket.connected) {
    const socket = io(socketURL, { autoConnect: false });
    setSocket(socket);
    // }
  }, []);

  const doCommand = useCallback(
    <T extends CameraCommandOptions>(
      camera_id: string,
      command: T["command"],
      data?: T["options"]
    ) => {
      if (socket) {
        if (data) {
          sendCommandUpdate(camera_id, command, data);
        } else {
          sendCommand(camera_id, command);
        }
      }
    },
    [socket]
  );

  const sendCommand = (deviceId: string, action: string) => {
    socket.emit("request", { action: action, deviceId: deviceId });
    // console.log("requested " + action);
  };

  const sendCommandUpdate = (deviceId: string, action: string, data: any) => {
    socket.emit("request", { action: action, deviceId: deviceId, data: data });
    // console.log("requested " + action + " using " + data);
  };

  const _cameras = useMemo(() => {
    if (!cameras) return [];
    if (cameras.length === 0) {
      return [];
    }
    return cameras.map(
      (camera) =>
        ({
          ...camera,
          cameraDisplay:
            camera.display === camera.id
              ? camera.display
              : camera.display + ` (${camera.id.substr(32)})`,
          ...((sockDevice && sockDevice[camera.id]) || {
            version: "",
            status: "",
            last_response: "",
          }),
        } as CameraDataControlType)
    );
  }, [cameras, sockDevice]);

  return { connected, doCommand, cameras: _cameras };
};
