import Grid from "@mui/material/Grid/Grid";
import MDBox from "components/MDBox";
import { DashboardWrapper } from "layouts/DashboardWrapper";
import { DataProvider, useData } from "../../context/DataProvider";
import { StricterAny } from "../../interfaces/StricterAny";
import TodaysAppointments from "../Calendar/TodaysAppointments";
import { CalendarData, CalendarListData } from "../Calendar/types";
import { CameraCalendarDataType } from "../Cameras/types";
import SystemMessages from "./cards/messages";
import { SystemMessage } from "./types/types";
import Gauge from "./guage/gauge";
import TipOfSpear from "./cards/tipofspear";

interface DataProps {
  banners: StricterAny[];
  cameras: CameraCalendarDataType[];
  calendar: CalendarData[];
  calendarList: CalendarListData[];
  gauge: StricterAny;
  incentives: StricterAny[];
  leaders: StricterAny[];
  mbaApps: StricterAny[];
  messages: SystemMessage[];
  reviews: StricterAny[];
}

function _Home() {
  const { data, refreshData, setUrl } = useData<DataProps>();
  console.log(data);
  return (
    <DashboardWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} xl={4}>
          <MDBox mb={3}>
            <TodaysAppointments
              cameras={(data && data.cameras) || []}
              calendar={(data && data.calendar) || []}
              calendarList={(data && data.calendarList) || []}
              refreshData={refreshData}
              onCalendarChange={(calendar) => setUrl(`stats?cal_id=${calendar}`)}
            />
          </MDBox>
        </Grid>
        {data?.messages && data?.messages.length > 0 && (
          <Grid item xs={12} sm={6} lg={4}>
            <SystemMessages messages={data?.messages || []} />
          </Grid>
        )}
        {data?.gauge && (
          <Grid item xs={12} sm={6} lg={4}>
            <TipOfSpear gauge={data?.gauge} levels={data?.incentives} />
          </Grid>
        )}
      </Grid>
    </DashboardWrapper>
  );
}

export function Home() {
  return (
    <DataProvider url={"stats"}>
      <_Home />
    </DataProvider>
  );
}
