import React, { useEffect } from "react";
import { DashboardWrapper } from "layouts/DashboardWrapper";
import { useGlobalState } from "context/GlobalStateProvider";
import { useNavigate } from "react-router-dom";
import { webDataStore } from "../../store/webDataStore";

export function Logout() {
  const { clearState } = useGlobalState();
  const navigate = useNavigate();

  useEffect(() => {
    // postData("logout").then(() => {
    // setState({userInfo: {token: null, id: 0, username: ""}, configData: null});
    // webDataStore.clearDataStore();
    clearState();
    navigate("/");
    // }).catch((err) => {
    //   console.log(err);
    // });
  }, [clearState, navigate]);

  return null;
  return (
    <DashboardWrapper>
      <div>Should be logging out at some point</div>
    </DashboardWrapper>
  );
}
