import { AxiosResponse } from "axios";

export function parseCookie(str: string) {
  return str
    .split(";")
    .map((v) => v.split("="))
    .reduce((acc, v) => {
      if (v.length > 1) {
        acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
      }
      return acc;
    }, {} as { [key: string]: string });
}

// export function parseBackendResponse<T>(response: AxiosResponse, cb: (error: Error | null, data: T) => void): void;
export function parseBackendResponse<T>(
  response: AxiosResponse,
  cb?: (error: Error | null, data: T) => void
): Promise<T> {
  const responseProcess = (
    response: AxiosResponse,
    callback: (error: Error | null, data: T) => void
  ) => {
    if (
      response.status !== 200 &&
      response.data.status !== "success" &&
      response.data.status !== "Success"
    ) {
      return callback(new Error(response.data?.message || response.statusText), null);
    }
    return callback(null, response.data.data);
  };

  if (typeof cb === "undefined") {
    return new Promise((resolve, reject) => {
      responseProcess(response, (error, data) => {
        if (error) {
          reject(error);
        } else {
          resolve(data);
        }
      });
    });
  }
  responseProcess(response, cb);
}

export function zLen(str: string, len: number) {
  while (str.length < len) {
    str = "0" + str;
  }
  return str;
}

export function zPad(str: string, len: number) {
  return zLen(str, len);
}
