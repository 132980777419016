import React, { createContext, useContext, useEffect, useState } from "react";
import { webDataStore } from "store/webDataStore";
import { Userinfo } from "interfaces/userinfo";
import { AuthResponseInterface, Configdata } from "interfaces/configdata";
import { useCookies } from "react-cookie";
import { postData } from "../api/api";
import { parseBackendResponse } from "../shared/util";
import { ClientEvent } from "clientevent";

// export interface UserInfo {
//   token: string;
//   username: string;
// }

export interface GlobalStateInterface {
  userInfo: null | Userinfo;
  attemptedTokenRead: boolean;
  configData: Configdata;
}

interface IGlobalStateContext {
  state: Partial<GlobalStateInterface>;
  setState: (partialState: Partial<GlobalStateInterface>) => void;
  clearState: () => void;
}

const GlobalStateContext = createContext({} as IGlobalStateContext);

const GlobalStateProvider = ({
  children,
  value = { attemptedTokenRead: false, userInfo: null } as GlobalStateInterface,
}: {
  children: React.ReactNode;
  value?: Partial<GlobalStateInterface>;
}) => {
  const [state, setStateOriginal] = useState(value);
  const [cookies, setCookies, removeCookies] = useCookies();
  const expiresDated = (expiresAt: number) => new Date(expiresAt * 1000);

  const clearState = () => {
    setStateOriginal({ attemptedTokenRead: true, userInfo: null } as GlobalStateInterface);
    webDataStore.clearDataStore();
    removeCookies("token", { path: "/" });
    removeCookies("loginID", { path: "/" });
    removeCookies("expiresAt", { path: "/" });
  };
  const setState = (value: Partial<GlobalStateInterface>) => {
    if (value.userInfo?.token && value.configData?.expiresAt && value.configData?.id) {
      setCookies("token", value.userInfo.token, {
        expires: expiresDated(value.configData.expiresAt),
        path: "/",
      });
      setCookies("loginID", value.configData.id, {
        expires: expiresDated(value.configData.expiresAt),
        path: "/",
      });
      setCookies("expiresAt", value.configData.id, {
        expires: expiresDated(value.configData.expiresAt),
        path: "/",
      });
    }
    if (value.userInfo?.token) {
      // if token is being set, set to global store for API
      webDataStore.setDataStore({ token: value.userInfo.token });
    }
    if (value.userInfo?.token === null) {
      // @ts-ignore
      webDataStore.data.token = undefined;
      // @ts-ignore
      delete webDataStore.data.token;
    }
    if (value?.configData?.perms) {
      webDataStore.setDataStore({ perms: value.configData.perms });
    }
    setStateOriginal((prev) => {
      return { ...prev, ...value };
    });
  };

  useEffect(() => {
    // const cookies = parseCookie(document.cookie);
    if (cookies.token && cookies.loginID && cookies.expiresAt) {
      webDataStore.setDataStore({ token: cookies.token });
      postData("reauth").then((data) => {
        parseBackendResponse<AuthResponseInterface>(data, (error, data) => {
          if (error) {
            console.error(error?.message || "Unknown Error");
            ClientEvent.publish("SNACKBAR", error?.message || "Unknown Error");
            setState({ attemptedTokenRead: true });
            return;
          }
          const { token, ...rest } = data;
          setState({
            userInfo: { username: "", token, id: rest.id },
            attemptedTokenRead: true,
            configData: { ...rest },
          });
        });
      });
      // setState({userInfo: {token: cookies.token, username: ""}, attemptedTokenRead: true})
    } else {
      setState({ attemptedTokenRead: true });
    }
  }, []);

  return (
    <GlobalStateContext.Provider value={{ state, setState, clearState }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

const useGlobalState = () => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error("useGlobalState must be used within a GlobalStateContext");
  }
  return context;
};

export { GlobalStateProvider, useGlobalState };
