import DashboardLayout from "../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../examples/Navbars/DashboardNavbar";
import MDBox from "../components/MDBox";
import Footer from "../examples/Footer";
import { LMSUserPicker } from "../pages/LMS/modals/LMSUserPicker";
import React from "react";

interface Props {
  children: JSX.Element | JSX.Element[];
}

export function DashboardWrapper(props: Props) {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>{props.children}</MDBox>
      <Footer />
      <LMSUserPicker />
    </DashboardLayout>
  );
}
