import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import MDPagination from "../MDPagination";
import Icon from "@mui/material/Icon";
import MDInput from "../MDInput";
import { useMemo } from "react";

interface Props {
  start: number;
  end: number;
  onChange: (page: number) => void;
  currentPage: number;
  perPage: number;
  totalEntries: number;
  showChevrons?: boolean;
  showTotalEntries?: boolean;
  pagination?: {
    variant: "contained" | "gradient";
    color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "dark" | "light";
  };
}

export function PageLists(props: Props) {
  const {
    start,
    end,
    onChange,
    currentPage,
    showChevrons,
    showTotalEntries,
    pagination,
    perPage,
    totalEntries,
  } = props;

  const pageOptions = useMemo(() => {
    if (start === end) {
      return [start];
    }
    return Array.from({ length: end - start + 1 }, (_, i) => i + start);
  }, [start, end]);

  const { entriesStart, entriesEnd } = useMemo(() => {
    const entriesStart = (currentPage - 1) * perPage + 1;
    const entriesEnd = Math.min(currentPage * perPage, end);
    return { entriesStart, entriesEnd };
  }, [currentPage, pageOptions, perPage]);

  const canPreviousPage = showChevrons && currentPage > start;
  const canNextPage = showChevrons && currentPage < end;

  const renderPagination = useMemo(
    () =>
      pageOptions.map((option: any) => (
        <MDPagination
          item
          key={`page-${option}`}
          onClick={() => onChange(Number(option))}
          active={currentPage === option}
        >
          {option + 1}
        </MDPagination>
      )),
    [pageOptions, currentPage, onChange]
  );

  return (
    <MDBox
      display="flex"
      flexDirection={{ xs: "column", sm: "row" }}
      justifyContent="space-between"
      alignItems={{ xs: "flex-start", sm: "center" }}
      p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
    >
      {showTotalEntries && (
        <MDBox mb={{ xs: 3, sm: 0 }}>
          <MDTypography variant="button" color="secondary" fontWeight="regular">
            Showing {entriesStart} to {entriesEnd} of {totalEntries} entries
          </MDTypography>
        </MDBox>
      )}
      {pageOptions.length > 1 && (
        <MDPagination
          variant={pagination && pagination.variant ? pagination.variant : "gradient"}
          color={pagination && pagination.color ? pagination.color : "info"}
        >
          {canPreviousPage && (
            <MDPagination item onClick={() => onChange(currentPage - 1)}>
              <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
            </MDPagination>
          )}
          {renderPagination.length > 6 ? (
            <MDBox width="5rem" mx={1}>
              <MDInput
                inputProps={{ type: "number", min: 1, max: pageOptions.length }}
                defaultValue={pageOptions[currentPage] + 1}
                // onChange={(event: any) => {
                //   onChange(Number(event.target.value));
                // }}
                onBlur={(event: any) => {
                  if (event.key === "Enter" && currentPage !== Number(event.target.value)) {
                    onChange(Number(event.target.value) - 1);
                  }
                }}
                onKeyDown={(event: any) => {
                  if (event.key === "Enter" && currentPage !== Number(event.target.value)) {
                    onChange(Number(event.target.value) - 1);
                  }
                }}
              />
            </MDBox>
          ) : (
            renderPagination
          )}
          {canNextPage && (
            <MDPagination item onClick={() => onChange(currentPage + 1)}>
              <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
            </MDPagination>
          )}
        </MDPagination>
      )}
    </MDBox>
  );
}
