import { DashboardWrapper } from "layouts/DashboardWrapper";
import { CircularProgress } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAPIGet } from "../../api/useAPIGet";

interface DataProps {
  AFBNEmail: string;
  AFBNPass: string;
}

export function MyEmail() {
  const { data } = useAPIGet<DataProps>("myemail");
  const navigate = useNavigate();
  const [error, setError] = React.useState<string>(null);
  const formRef = React.useRef<HTMLFormElement>(null);

  React.useEffect(() => {
    if (formRef.current) {
      formRef.current.submit();
      navigate("/home");
    }
  }, [data]);

  return (
    <DashboardWrapper>
      {error ? (
        <div>{error}</div>
      ) : (
        <div>
          <CircularProgress />
          <div>
            <h4 className="pt-4" style={{ textAlign: "center" }}>
              Please wait while we attempt to connect you to your email account....
            </h4>
          </div>
          {data && data.AFBNEmail && data.AFBNPass && (
            <form
              ref={formRef}
              id="EmailWindow"
              method="post"
              target={"_blank"}
              action="https://mail.liveoutlook.net/owa/auth.owa"
            >
              <input type="hidden" name="destination" value="https://mail.liveoutlook.net/owa" />
              <input type="hidden" name="flags" value="4" />
              <input type="hidden" name="forcedownlevel" value="0" />
              <input type="hidden" name="username" value={data.AFBNEmail} />
              <input type="hidden" name="password" value={data.AFBNPass} />
              <input name="isUtf8" value="1" type="hidden" />
              <input type="submit" style={{ display: "none" }} />
            </form>
          )}
        </div>
      )}
    </DashboardWrapper>
  );
}
