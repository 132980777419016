/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components

// Material Dashboard 2 PRO React TS components

// Material Dashboard 2 PRO React TS examples components
import React from "react";
import gaugeBG from "./gaugeBG.png";
import gaugeNeedle from "./gaugeNeedle.png";

interface Props {
  // messages: SystemMessage[];
  value?: number;
  display?: string;
}

function _Gauge(props: Props): JSX.Element {
  if (props.value === undefined) return null;
  let value = props.value;
  if (value > 100) {
    value = 100;
  } else if (value < 0) {
    value = 0;
  }
  const rotation = -126 + (value / 100) * 248;

  return (
    <div
      style={{
        position: "absolute",
        width: 300,
        height: 300,
        maxHeight: 300,
        maxWidth: 300,
      }}
    >
      <div style={{ position: "absolute", width: "100%", height: "100%" }}>
        <img src={gaugeBG} style={{ width: "100%", height: "auto" }} />
      </div>
      <div style={{ position: "absolute", width: "100%", height: "100%" }}>
        <img
          src={gaugeNeedle}
          style={{
            display: "block",
            marginTop: 40,
            transformOrigin: "75px 110px",
            marginLeft: "auto",
            marginRight: "auto",
            height: "75%",
            width: "50%",
            transform: `rotate(${rotation}deg)`,
            transition: "transform 1s ease-in-out",
          }}
        />
      </div>
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 203,
          textAlign: "center",
        }}
      >
        {props.display}
      </div>
    </div>
  );
}

export const Gauge = React.memo(_Gauge);

export default Gauge;
