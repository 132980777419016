import React, { useContext, useEffect } from "react";
import { getData } from "../api/api";
import { parseBackendResponse } from "../shared/util";
import { ClientEvent } from "clientevent";

export interface DataProviderType<T> {
  data: T;
  refreshData: () => void;
  setUrl: (url: string) => void;
}

const DataContext = React.createContext<DataProviderType<any>>(null);

export function DataProvider<T>(props: { children: React.ReactNode; url: string }) {
  const [data, setData] = React.useState<T>(null);
  const [_url, setUrl] = React.useState<string>(props.url);

  const refreshData = () => {
    getData(_url)
      .then(async (data) => await parseBackendResponse<T>(data))
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        ClientEvent.publish("SNACKBAR", error.message);
        console.error(error);
      });
  };
  useEffect(() => {
    refreshData();
  }, [_url]);

  return (
    <DataContext.Provider value={{ data, setUrl, refreshData }}>
      {props.children}
    </DataContext.Provider>
  );
}

export function useData<T>() {
  const context = useContext<DataProviderType<T>>(DataContext);
  if (!context) {
    throw new Error("useGlobalState must be used within a GlobalStateContext");
  }
  return context;
}
