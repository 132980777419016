import DataTable from "components/DataTable";
import { DashboardWrapper } from "layouts/DashboardWrapper";
import { CameraDataControlType, CameraDataType } from "./types";
import MDButton from "../../components/MDButton";
import React, { useCallback } from "react";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom";
import { useAPIGet } from "../../api/useAPIGet";
import { useCameraControl } from "./hooks/useCameraControl";
import {
  StyledDropdownMenu,
  StyledDropdownMenuAction,
  StyledDropdownMenuOption,
} from "../../components/StyledMenu/StyledMenu";
import {
  buttonDisable,
  buttonText,
  CameraButtonColor,
  displayDate,
  isAvailable,
  statusText,
} from "./shared/utils";
import { usePermissions } from "../../hooks/usePermissions";
import MDBox from "../../components/MDBox";
import { zuluToHuman } from "../../shared/dateutils";

export function CameraList() {
  const navigate = useNavigate();
  const [showAll, setShowAll] = React.useState<boolean>(false);
  const { data, refreshData } = useAPIGet<{ cameras: CameraDataType[] }>(
    `camera/list${showAll ? "all" : ""}`
  );
  const { doCommand, connected, cameras } = useCameraControl(data && data.cameras);
  const { canCameraListAll, canCameraDebug, canCameraAssignPermissions } = usePermissions();

  const handleMenuCallback = useCallback(
    (id: string, action: string) => {
      switch (action) {
        case "record":
        case "still":
        case "stop":
        case "preview":
        case "restart":
        case "shutdown":
          doCommand(id, action);
          break;
        case "viewmedia":
          navigate(`/cameras/${id}/media`);
          break;
        default:
          console.log("unhandled action", id, action);
      }
    },
    [cameras]
  );

  const generateMenuItems = (
    camera: CameraDataControlType
  ): { [key: string]: StyledDropdownMenuOption } => {
    const response: { [key: string]: StyledDropdownMenuAction } = {};
    if (camera.access !== 0 && isAvailable(camera.status)) {
      if (camera.status === "ready") {
        response["record"] = { type: "action", display: "Record" };
        response["still"] = { type: "action", display: "Still" };
        response["record-div"] = { type: "divider" };
        // response["preview"] = {type: "action", display: "Preview"};
      } else if (camera.status === "recording") {
        response["stop"] = { type: "action", display: "Stop Recording" };
        response["record-div"] = { type: "divider" };
      }
      response["viewmedia"] = { type: "action", display: "View Media" };

      if (camera.status === "ready") {
        response["restart"] = { type: "action", display: "Restart" };
        if (canCameraDebug) {
          response["shutdown"] = { type: "action", display: "Shutdown" };
        }
      }
    }
    return response;
  };

  return (
    <DashboardWrapper>
      {/*<LMSUserPicker/>*/}
      <DataTable<CameraDataControlType>
        // isSorted={true}
        middleHeader={
          <MDBox ml={5}>
            <Icon
              color={connected ? "info" : undefined}
              title={connected ? "Connected" : "Offline"}
            >
              language
            </Icon>
          </MDBox>
        }
        endHeader={
          <MDBox ml={2}>
            <MDButton iconOnly={true} variant="gradient" color="dark" onClick={() => refreshData()}>
              <Icon title={"Refresh"}>refresh</Icon>
            </MDButton>
            {canCameraListAll && (
              <>
                <MDButton
                  iconOnly={true}
                  variant="gradient"
                  color="dark"
                  onClick={() => setShowAll(!showAll)}
                >
                  <Icon title={!showAll ? "View All" : "View Assigned"}>camera</Icon>
                </MDButton>
                <MDButton
                  iconOnly={true}
                  variant="gradient"
                  color="dark"
                  onClick={() => navigate("/cameras/allmedia")}
                >
                  <Icon title={"View All Media"}>subscriptions</Icon>
                </MDButton>
              </>
            )}
          </MDBox>
        }
        canSearch={true}
        table={{
          columns: [
            {
              Header: "Display",
              accessor: "cameraDisplay",
              width: "30%",
            },
            {
              Header: "Status/Version",
              accessor: "status",
              width: "10%",
              Cell: ({ cell }) => (
                <div>
                  {statusText(cell.row.original.status)}
                  <span style={{ fontSize: 8, float: "right" }}>{cell.row.original.version}</span>
                </div>
              ),
            },
            {
              Header: "Last Response",
              accessor: "last_response",
              width: "15%",
              Cell: ({ cell: { value, row } }) => (
                <>
                  {value && Number(value) > 0
                    ? displayDate(Number(value))
                    : zuluToHuman(row.original.last_time)}
                </>
              ),
            },
            {
              Header: "Actions",
              accessor: "id",
              width: "20%",
              align: "right",
              disableSortBy: true,
              Cell: ({ cell: { value, row } }) => (
                <>
                  {row.original.access !== 0 && isAvailable(row.original.status) ? (
                    <MDButton
                      iconOnly={true}
                      variant="gradient"
                      disabled={buttonDisable(row.original.status, connected)}
                      color={CameraButtonColor(row.original.status)}
                      title={buttonText(row.original.status)}
                      onClick={() => {
                        if (row.original.status === "ready") {
                          doCommand(String(value), "record");
                        } else if (row.original.status === "recording") {
                          doCommand(String(value), "stop");
                        }
                      }}
                    >
                      {row.original.status === "ready" && <Icon>fiber_manual_record</Icon>}
                      {row.original.status === "recording" && <Icon>square</Icon>}
                    </MDButton>
                  ) : (
                    <MDButton
                      iconOnly={true}
                      variant="gradient"
                      color="info"
                      title={"View Media"}
                      onClick={() => navigate(`/cameras/${value}/media`)}
                    >
                      <Icon>subscriptions</Icon>
                    </MDButton>
                  )}
                  <StyledDropdownMenu
                    options={generateMenuItems(row.original)}
                    id={String(value)}
                    onAction={handleMenuCallback}
                    optionTitle={"Camera Options"}
                  />
                  {canCameraAssignPermissions && (
                    <MDButton
                      iconOnly={true}
                      variant="gradient"
                      color={"dark"}
                      title={"Manage Users"}
                      onClick={() => {}}
                    >
                      <Icon>people</Icon>
                    </MDButton>
                  )}
                </>
              ),
            },
          ],
          rows: cameras || [],
        }}
      />
    </DashboardWrapper>
  );
}
