/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import MessageItem from "./MessageItem";
import { SystemMessage } from "../types/types";
import React from "react";

interface Props {
  messages: SystemMessage[];
}

export default function SystemMessages(props: Props): JSX.Element {
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={2} px={2} lineHeight={0.5}>
        <MDTypography variant="h6" fontWeight="medium">
          System Messages
        </MDTypography>
      </MDBox>
      <MDBox p={2} maxHeight={300} overflow={"auto"}>
        {props.messages.map((message, index) => (
          <MessageItem
            color="dark"
            icon="savings"
            title={`${message.dated.split("T")[0]} - ${message.title}`}
            description={message.message}
          />
        ))}
      </MDBox>
      <MDBox m={1} />
    </Card>
  );
}
