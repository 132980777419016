import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";
import { DialogActionsProps } from "@mui/material/DialogActions/DialogActions";

interface Props {
  title: string | JSX.Element;
  children: string | JSX.Element | JSX.Element[];

  actions: string | JSX.Element | JSX.Element[];
  open?: boolean;
  onClose?: () => void;
  actionsProps?: DialogActionsProps;
  fullWidth?: boolean;
}

export function ModalMaker(props: Props) {
  // const [open, setOpen] = useState<boolean>(props.defaultOpen || false);

  return (
    <Dialog
      open={props.open || false}
      onClose={() => props.onClose && props.onClose()}
      fullWidth={props.fullWidth || true}
      maxWidth={"md"}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>{props.open && props.children}</DialogContent>
      <DialogActions {...((props && props.actionsProps) || {})}>
        {props.open && props.actions}
      </DialogActions>
    </Dialog>
  );
}
