import { useMemo } from "react";
import { hasPermission, RequiredPermissions } from "../shared/permissionutils";
import { useGlobalState } from "../context/GlobalStateProvider";

export const usePermissions = () => {
  const {
    state: { configData },
  } = useGlobalState();
  return useMemo(() => {
    return {
      canApplicationView: hasPermission(RequiredPermissions.ApplicationsView),
      canCameraAccess: hasPermission(RequiredPermissions.CameraAccess),
      canCameraListAll: hasPermission(RequiredPermissions.CameraListAll),
      canCameraDebug: hasPermission(RequiredPermissions.CameraDebug),
      canCameraAssignPermissions: hasPermission(RequiredPermissions.CameraAssignPermissions),
    };
  }, [configData]);
};
