/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import { CalendarData, CalendarListData } from "../types";
import { useGlobalState } from "../../../context/GlobalStateProvider";
import React, { useEffect, useMemo } from "react";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useAPIGet } from "../../../api/useAPIGet";
import { CameraCalendarDataType, CameraDataControlType, CameraDataType } from "../../Cameras/types";
import { useCameraControl } from "../../Cameras/hooks/useCameraControl";
import {
  StyledDropdownMenu,
  StyledDropdownMenuOption,
} from "../../../components/StyledMenu/StyledMenu";
import {
  buttonDisable,
  buttonText,
  CameraButtonColor,
  isReady,
  isRecording,
} from "../../Cameras/shared/utils";
import Icon from "@mui/material/Icon";
import MDButton from "../../../components/MDButton";
import CalendarItem from "./CalendarItem";
import {
  displayMilHoursMinutesFromTime,
  forceSingleTimeZone,
  formatLocalDate,
  formatTimeFromEpochAFBN,
} from "../../../shared/dateutils";
import moment from "moment";

interface Props {
  cameras: CameraCalendarDataType[];
  calendar: CalendarData[];
  calendarList: CalendarListData[];
  refreshData: () => void;
  onCalendarChange: (calendar: number) => void;
}

interface EntryRefs {
  [key: string]: HTMLDivElement;
}

function TodaysAppointments(props: Props): JSX.Element {
  const {
    state: {
      configData: { id: user_id },
    },
  } = useGlobalState();
  const [apptCal, setApptCal] = React.useState<number>(user_id);
  const { calendar, calendarList } = props;
  const calList = useMemo(() => {
    return (calendarList && calendarList.filter((val) => val.cal_id !== user_id)) || [];
  }, [calendarList, user_id]);
  // const { data } = useAPIGet<{ cameras: CameraDataType[] }>(`camera/list`);
  const { doCommand, connected, cameras } = useCameraControl(props.cameras);
  const entryRefs = React.useRef<EntryRefs>({});

  const handleCalendarChange = (event: SelectChangeEvent) => {
    setApptCal(Number(event.target.value));
    props.onCalendarChange(Number(event.target.value));
  };

  const handleMenuCallback =
    (lmsId: number, action: "record" | "stop") => (calId: string, cameraId: string) => {
      doCommand(cameraId, action, {
        lmsId,
        calId: Number(calId),
      });
    };

  const { availableCamera, recordingCameras, getCalRecordingCam } = useMemo(() => {
    const recorders =
      (cameras &&
        cameras
          .filter((camera) => camera.access !== 0 && isReady(camera.status))
          .reduce(
            (k: { [key: string]: StyledDropdownMenuOption }, v: CameraDataControlType) => ({
              ...k,
              [v.id]: {
                type: "action",
                display: v.cameraDisplay,
              } as unknown as StyledDropdownMenuOption,
            }),
            {} as unknown as { [key: string]: StyledDropdownMenuOption }
          )) ||
      {};
    const recordingCameras =
      ((cameras &&
        cameras
          .filter((camera) => camera.access !== 0 && isRecording(camera.status))
          .reduce(
            (k: { [key: string]: CameraDataControlType }, v: CameraDataControlType) => ({
              ...k,
              [v.id]: v,
            }),
            {} as unknown as { [key: string]: CameraDataControlType }
          )) as { [key: string]: CameraDataControlType }) || {};
    let recordingCamerasMenu = {};
    if (Object.keys(recorders).length > 0) {
      recordingCamerasMenu = {
        subheader: { type: "header", display: "Record Using..." },
        ...recorders,
      };
    }
    // console.log(recordingCamerasMenu, recordingCameras);
    return {
      availableCamera: recordingCamerasMenu,
      recordingCameras,
      getCalRecordingCam: (calId: number) =>
        // @ts-ignore
        Object.values(recordingCameras).find((val) => val.cal_id === calId),
    };
  }, [cameras]);

  const calendarItems = useMemo(() => {
    return calendar.filter((cal: CalendarData) => {
      // console.log(cal.starttime, formatTimeFromEpoch(cal.starttime));
      let startTime = formatTimeFromEpochAFBN(cal.starttime);
      // let endTime = formatTimeFromEpoch(cal.endtime);

      let startOfDay = moment(`${formatLocalDate(new Date())} 00:00:00`);
      let endOfDay = moment(`${formatLocalDate(new Date())} 23:59:59`);
      // let startOfDay = parseDate(`${formatDate(new Date())} 00:00:00`);
      // let endOfDay = parseDate(`${formatDate(new Date())} 23:59:59`);
      // console.log(startOfDay, endOfDay);
      // console.log(startTime.valueOf());
      // console.log(endTime.valueOf());
      // console.log(startOfDay.valueOf());
      // console.log(endOfDay.valueOf());
      return (
        startTime.valueOf() >= startOfDay.valueOf() && startTime.valueOf() <= endOfDay.valueOf()
      );
      // return formatTimeFromEpoch(cal.starttime).valueOf() / 1000 >= moment(`${formatDate(new Date())} 00:00:00`).unix() && forceSingleTimeZone(formatTimeFromEpoch(cal.endtime)).valueOf() / 1000 <= moment(`${formatDate(new Date())} 23:59:59`).unix()
      // return forceSingleTimeZone(formatTimeFromEpoch(cal.starttime)).valueOf() / 1000 >= moment(`${formatDate(new Date())} 00:00:00`).unix() && forceSingleTimeZone(formatTimeFromEpoch(cal.endtime)).valueOf() / 1000 <= moment(`${formatDate(new Date())} 23:59:59`).unix()
    });
  }, [calendar]);

  useEffect(() => {
    if (calendarItems.length > 0) {
      let data1 = Math.round(new Date().valueOf() / 1000);
      const timeNow = Number(displayMilHoursMinutesFromTime(new Date()));
      let autoScroll: null | number = null;
      calendarItems.forEach((item) => {
        const theTime = Number(
          displayMilHoursMinutesFromTime(
            forceSingleTimeZone(formatTimeFromEpochAFBN(item.starttime))
          )
        );
        if (theTime > timeNow && !autoScroll) {
          autoScroll = item.id;
        }
      });
      if (autoScroll) {
        setTimeout(() => {
          entryRefs.current[autoScroll] &&
            entryRefs.current[autoScroll].scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
        }, 100);
      }
      // entryRefs.current = calendarItems.reduce((k: EntryRefs, v: CalendarData) => {
      //   k[v.id] = React.createRef();
      //   return k;
      // }, {});
    }
  }, [calendarItems]);

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDBox ml={0}>
          <MDTypography variant="h6" fontWeight="medium">
            Today's Appointments
          </MDTypography>
        </MDBox>
        <MDBox mr={0}>
          <Icon color={connected ? "info" : undefined} title={connected ? "Connected" : "Offline"}>
            language
          </Icon>
          <MDButton
            iconOnly={true}
            variant="gradient"
            color="dark"
            onClick={() => props.refreshData()}
          >
            <Icon title={"Refresh"}>refresh</Icon>
          </MDButton>
        </MDBox>
      </MDBox>
      <MDBox px={2} mt={0} width={"100%"}>
        {apptCal && (
          <Select
            value={String(apptCal)}
            color={"secondary"}
            // label="Calendar"
            fullWidth
            onChange={handleCalendarChange}
          >
            <MenuItem key={String(user_id)} value={user_id}>
              My Calendar
            </MenuItem>
            {calList.map((val) => (
              <MenuItem
                key={String(val.cal_id)}
                value={val.cal_id}
              >{`${val.agencyDisplay}-${val.userDisplay}`}</MenuItem>
            ))}
          </Select>
        )}
      </MDBox>
      <MDBox p={2} maxHeight={300} overflow={"auto"}>
        {(!calendarItems || calendarItems.length === 0) && (
          <MDTypography display="block" variant="button" fontWeight="medium" fontSize={12}>
            No appointments
          </MDTypography>
        )}
        {(calendarItems &&
          calendarItems.map((val) => {
            const camRecording = getCalRecordingCam(val.id);
            return (
              <CalendarItem
                key={val.id}
                ref={(ref: HTMLDivElement) => (entryRefs.current[val.id] = ref)}
                color="info"
                icon={"fiber_manual_record"}
                title={val.subject}
                description={displayMilHoursMinutesFromTime(
                  forceSingleTimeZone(formatTimeFromEpochAFBN(val.starttime))
                )}
                endingContent={
                  <>
                    {val.lmsID ? (
                      <>
                        {!!camRecording ? (
                          <MDButton
                            iconOnly={true}
                            variant="gradient"
                            color={"error"}
                            title={"Stop Recording"}
                            onClick={() => {
                              doCommand(String(camRecording.id), "stop");
                            }}
                          >
                            <Icon>square</Icon>
                          </MDButton>
                        ) : (
                          <StyledDropdownMenu
                            options={availableCamera}
                            id={String(val.id)}
                            onAction={handleMenuCallback(val.lmsID, "record")}
                            optionTitle={"Record"}
                            optionIcon={"fiber_manual_record"}
                          />
                        )}
                      </>
                    ) : (
                      <MDTypography fontSize={10}>No Lead</MDTypography>
                    )}
                  </>
                }
              />
            );
          })) ||
          null}
      </MDBox>
      <MDBox m={1} />
    </Card>
  );
}

export default TodaysAppointments;
