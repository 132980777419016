/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { ChangeEvent, useState, KeyboardEvent } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
import bgImage from "assets/images/AgentPortalBG.jpg";
import { postData } from "../../api/api";
import { useGlobalState } from "../../context/GlobalStateProvider";
import { ClientEvent } from "clientevent";

function Login(): JSX.Element {
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [userVals, setUserVals] = useState<{ email: string; password: string }>({
    email: "",
    password: "",
  });
  const { setState } = useGlobalState();

  const userValHandler = (key: "email" | "password", val: string) => {
    setUserVals((prev) => ({ ...prev, [key]: val }));
  };

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  let onSubmit = () => {
    postData("login", { username: userVals.email, password: userVals.password })
      .then(async (result) => {
        if (result.status === 200 && result.data.status === "success") {
          const { token, ...rest } = result.data.data;
          setState({
            userInfo: { username: userVals.email, token, id: rest.id },
            attemptedTokenRead: true,
            configData: { ...rest },
          });
        } else {
          result.statusText = result.data.message;
          ClientEvent.publish("SNACKBAR", `Error: ${result.statusText}`);
          console.log(result.statusText);
        }
      })
      .catch((err) => {
        console.log(err);
        ClientEvent.publish("SNACKBAR", `Error: ${err.response.statusText}`);
      });
  };

  return (
    <IllustrationLayout
      title="Sign In"
      description="Enter your username and password"
      illustration={bgImage}
    >
      <MDBox component="form" role="form">
        <MDBox mb={2}>
          <MDInput
            type="text"
            label="Username"
            fullWidth
            value={userVals.email}
            onChange={(evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
              userValHandler("email", evt.target.value)
            }
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="password"
            label="Password"
            fullWidth
            value={userVals.password}
            onKeyUp={(evt: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              if (evt.key === "Enter") {
                evt.preventDefault();
                evt.stopPropagation();
                onSubmit();
              }
            }}
            onChange={(evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
              userValHandler("password", evt.target.value)
            }
          />
        </MDBox>
        {/*<MDBox display="flex" alignItems="center" ml={-1}>*/}
        {/*  <Switch checked={rememberMe} onChange={handleSetRememberMe}/>*/}
        {/*  <MDTypography*/}
        {/*    variant="button"*/}
        {/*    fontWeight="regular"*/}
        {/*    color="text"*/}
        {/*    onClick={handleSetRememberMe}*/}
        {/*    sx={{cursor: "pointer", userSelect: "none", ml: -1}}*/}
        {/*  >*/}
        {/*    &nbsp;&nbsp;Remember me*/}
        {/*  </MDTypography>*/}
        {/*</MDBox>*/}
        <MDBox mt={4} mb={1}>
          <MDButton variant="gradient" color="info" size="large" fullWidth onClick={onSubmit}>
            sign in
          </MDButton>
        </MDBox>
        {/*<MDBox mt={3} textAlign="center">*/}
        {/*  <MDTypography variant="button" color="text">*/}
        {/*    Don&apos;t have an account?{" "}*/}
        {/*    <MDTypography*/}
        {/*      component={Link}*/}
        {/*      to="/authentication/sign-up/cover"*/}
        {/*      variant="button"*/}
        {/*      color="info"*/}
        {/*      fontWeight="medium"*/}
        {/*      textGradient*/}
        {/*    >*/}
        {/*      Sign up*/}
        {/*    </MDTypography>*/}
        {/*  </MDTypography>*/}
        {/*</MDBox>*/}
      </MDBox>
    </IllustrationLayout>
  );
}

export default Login;
