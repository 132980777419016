import { Player } from "video-react";
import React from "react";
import "video-react/dist/video-react.css";

interface Props {
  url: string;
}

export function VideoPlayer(props: Props) {
  return <Player playsInline src={props.url} />;
}
