import { ApplicationPerms, PermissionModel } from "../interfaces/permissions";
import { webDataStore } from "../store/webDataStore";

function objectIsEmpty(obj: object) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

function breakItDown(perms: any, required: any, response: boolean = false) {
  try {
    for (var key in required) {
      if (typeof required[key] == "object") {
        //loop through each base type required
        //console.log("loop through each base type required:", required[key]);
        if (key in perms) {
          //console.log("Key in perms:", (key));
          if (typeof required[key] == "object") {
            var passing = 0;
            var scored = 0;
            //more properties to get
            //console.log("more properties to get:",required[key]);
            for (var key2 in required[key]) {
              if (typeof required[key][key2] === "object") {
                //loop through each required permission
                //console.log("Loop through each req permissions:",required[key][key2]);
                if (key2 in perms[key]) {
                  //Still alive and checks out so far. See just how much access.
                  //console.log("Still here:",perms[key][key2]);
                  var arrReq = required[key][key2];
                  var arrPerms = perms[key][key2];
                  passing = passing + arrReq.length;
                  //var scored = 0;
                  for (var a = 0; a < arrPerms.length; a++) {
                    for (var b = 0; b < arrReq.length; b++) {
                      //console.log("checking ",arrReq[b], " against ",arrPerms[a]);
                      if (arrReq[b] == arrPerms[a]) {
                        scored++;
                        //console.log("yes");
                      }
                    }
                  }
                }
              }
            }
            if (passing > 0 && passing <= scored) {
              response = true;
            }
            //console.log("Passing:", passing , "Scored:",scored,"  Passed:", (passing <= scored));
          }
        }
      }
    }
  } catch (error) {
    console.log("Permission Check Error", error);
    return response;
  }
  return response;
}

const emptyAdmin: ApplicationPerms = {
  //[]-No Access, [1]-View, [2]-Add, [3]-Edit
  applications: [],
  users: [],
  cameras: [],
  careers: [],
  lms: [],
  calendar: [],
  splash: [],
  briefcase: [],
  leads: [],
  taxes: [],
  store: [],
  resources: [],
  training: [],
  events: [],
  reviews: [],
  intakes: [],
};
const emptyTemplates = [emptyAdmin, {}, {}, {}, {}, {}];
const typeArray = ["admin", "", "", "", "", ""];

export function calcPerms(type: string, strObj: string) {
  //get a type value and permission json string, parse into permissions object
  //console.log(strObj);
  var typeNum = 0;
  try {
    typeNum = parseInt(type, 10);
  } catch (error) {
    return {
      type: -1,
      strType: "invalid",
      [strType]: {},
    };
  }
  if (typeNum < 0 || typeNum > 5) {
    return {
      type: -1,
      strType: "invalid",
      [strType]: {},
    };
  }
  var strType = typeArray[typeNum];
  var perms = {};
  if (strObj.length === 0) {
    perms = emptyTemplates[typeNum];
  } else {
    try {
      perms = JSON.parse(strObj);
    } catch (e) {
      perms = emptyTemplates[typeNum];
    }
  }
  return {
    type: type,
    strType: typeArray[typeNum],
    [typeArray[typeNum]]: perms,
  };
}

export function checkPerms(perms: PermissionModel, required: { admin: ApplicationPerms }[]) {
  var response = false;
  if (!required || required == undefined) return true;
  if (objectIsEmpty(required)) {
    return true;
  }
  if (!perms) return response;
  if (objectIsEmpty(perms)) {
    return response;
  }
  var role = perms.strType;
  //console.log("permissions",perms);
  //console.log("page requires",required);

  if (Array.isArray(required)) {
    for (var c = 0; c < required.length; c++) {
      response = breakItDown(perms, required[c], response);
    }
  } else {
    response = breakItDown(perms, required, response);
  }
  return response;
}

export interface RequiredPermissionsChoices {
  [key: string]: { admin: ApplicationPerms }[];
}

export const RequiredPermissions: RequiredPermissionsChoices = {
  None: [],
  ApplicationsView: [{ admin: { applications: [1] } }],
  // ApplicationsAndStudentsView: [{admin: {students: [1], attendance: [1]}}],
  CalendarSelfView: [{ admin: { calendar: [1] } }],
  CalendarEdit: [{ admin: { calendar: [2] } }],
  CalendarRemove: [{ admin: { calendar: [3] } }],
  CalendarAAREdit: [{ admin: { calendar: [4] } }],
  BriefcaseDelete: [{ admin: { briefcase: [4] } }],
  CameraAccess: [{ admin: { cameras: [1] } }],
  CameraListAll: [{ admin: { cameras: [2] } }],
  CameraDebug: [{ admin: { cameras: [3] } }],
  CameraAssignPermissions: [{ admin: { cameras: [4] } }],
  LMSView: [{ admin: { lms: [1] } }],
  LMSSave: [{ admin: { lms: [2] } }],
  LMSRemove: [{ admin: { lms: [3] } }],
  LMSBlacklist: [{ admin: { lms: [4] } }],
  StoreEdit: [{ admin: { store: [2] } }],
  StoreRemove: [{ admin: { store: [3] } }],
  ResourcesEdit: [{ admin: { resources: [2] } }],
  ResourcesRemove: [{ admin: { resources: [3] } }],
  TrainingEdit: [{ admin: { training: [2] } }],
  TrainingRemove: [{ admin: { training: [3] } }],
  TrainingReport: [{ admin: { training: [4] } }],
  EventInviteOnly: [{ admin: { events: [1] } }],
  EventAll: [{ admin: { events: [2] } }],
  EventRemove: [{ admin: { events: [3] } }],
  ReviewViewAddOwn: [{ admin: { reviews: [1] } }],
  ReviewViewAddAll: [{ admin: { reviews: [2] } }],
  ReviewApprovalRemove: [{ admin: { reviews: [3] } }],
};

export function hasPermission(required: { admin: ApplicationPerms }[]) {
  // const perms = testPerms.perms as PermissionModel;
  const perms = webDataStore.data.perms || ({} as PermissionModel);
  return checkPerms(perms, required);
}

const testPerms = {
  perms: {
    type: 0,
    strType: "admin",
    admin: {
      applications: [1],
      users: [1, 2, 3, 4],
      cameras: [1, 2, 3, 4],
      careers: [1, 2, 3],
      lms: [1, 2, 3, 4],
      calendar: [1, 2, 3],
      splash: [2],
      briefcase: [1, 2, 3, 4],
      leads: [1, 2, 3, 4],
      taxes: [1, 2, 3, 4],
      store: [1, 2, 3],
      resources: [1, 2, 3],
      training: [1, 2, 3, 4],
      events: [1, 2, 3, 4],
      reviews: [1, 2, 3],
      intakes: [1, 2, 3],
    },
  },
};
