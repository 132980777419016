import { PermissionModel } from "interfaces/permissions";

export const webDataStore = {
  data: {
    login: "",
    password: "",
    token: "",
    pushToken: "",
    updateAvailable: false,
    updateReady: false,
  } as WebDataStoreOptions,
  setDataStore(props: Partial<WebDataStoreOptions>) {
    this.data = { ...this.data, ...props };
  },
  clearDataStore() {
    this.data = {
      login: "",
      password: "",
      token: "",
      pushToken: "",
      updateAvailable: false,
      updateReady: false,
      perms: null,
    };
  },
};

interface WebDataStoreOptions {
  login: string;
  password: string;
  token: string;
  pushToken: string;
  updateAvailable: boolean;
  updateReady: boolean;
  perms: PermissionModel | null;
}
