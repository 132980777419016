import { DashboardWrapper } from "layouts/DashboardWrapper";
import { useNavigate, useParams } from "react-router-dom";
import { useGlobalState } from "../../context/GlobalStateProvider";
import { CalendarData, CalendarListData } from "./types";
import { useAPIGet } from "../../api/useAPIGet";
import React, { useMemo } from "react";
import EventCalendar from "../../examples/Calendar";
import Grid from "@mui/material/Grid";
import { calendarDataRenderer } from "./utils/utils";
import { EventClickArg } from "@fullcalendar/react";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import MDBox from "../../components/MDBox";
import Icon from "@mui/material/Icon";
import MDButton from "../../components/MDButton";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

interface DataProps {
  calendar: CalendarData[];
  calendarList: CalendarListData[];
}

export function Calendar() {
  const { cal_id } = useParams<{ cal_id?: string }>();
  const {
    state: {
      userInfo: { id: userId },
    },
  } = useGlobalState();
  const { data, refreshData } = useAPIGet<DataProps>(`calendar/feed/${cal_id || userId}`);
  const calList = useMemo(
    () => data?.calendarList.filter((item) => item.cal_id !== userId) || [],
    [data?.calendarList]
  );
  const navigate = useNavigate();
  return (
    <DashboardWrapper>
      <Grid item xs={12} xl={9} sx={{ height: "max-content" }}>
        {useMemo(
          () => (
            <EventCalendar
              initialView="dayGridMonth"
              headerToolbar={{
                left: "prev,next,today",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridThreeDay,timeGridDay,listWeek",
              }}
              now={new Date()}
              events={data?.calendar || []}
              selectable
              editable
              topComponent={
                <MDBox
                  px={2}
                  mt={0}
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  <MDBox ml={4} width={"50%"}>
                    {calList && (
                      <Select
                        value={String(cal_id || userId)}
                        color={"secondary"}
                        // label="Calendar"
                        fullWidth
                        onChange={(newVal: SelectChangeEvent<string>) => {
                          navigate(`/calendar/${newVal.target.value}`);
                        }}
                      >
                        <MenuItem key={String(userId)} value={userId}>
                          My Calendar
                        </MenuItem>
                        {calList.map((val) => (
                          <MenuItem
                            key={String(val.cal_id)}
                            value={val.cal_id}
                          >{`${val.agencyDisplay}-${val.userDisplay}`}</MenuItem>
                        ))}
                      </Select>
                    )}
                  </MDBox>
                  <MDBox>
                    <MDButton
                      iconOnly={true}
                      variant="gradient"
                      color="info"
                      title={"Refresh"}
                      onClick={() => refreshData()}
                    >
                      <Icon>refresh</Icon>
                    </MDButton>
                  </MDBox>
                </MDBox>
              }
              eventClick={(event: EventClickArg) => {
                console.log(event.event.extendedProps);
              }}
              eventContent={renderEventContent}
              eventDataTransform={calendarDataRenderer}
            />
          ),
          [data?.calendar, calList]
        )}
      </Grid>
    </DashboardWrapper>
  );
}

function renderInnerContent(innerProps: any): React.ReactElement<any, any> {
  return (
    <div className="fc-event-main-frame">
      {innerProps.timeText && <div className="fc-event-time">{innerProps.timeText}</div>}
      <div className="fc-event-title-container">
        <div className="fc-event-title fc-sticky">{innerProps.event.title || <>&nbsp;</>}</div>
      </div>
    </div>
  );
}

function renderEventContent(eventInfo: any, other: any) {
  if (eventInfo.view.type.startsWith("timeGrid")) {
    return (
      <Tippy content={eventInfo.event.title}>
        <div className="fc-event-title">{eventInfo.event.title || <>&nbsp;</>}</div>
      </Tippy>
    );
  }
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        borderColor: eventInfo.borderColor || eventInfo.backgroundColor,
        borderStyle: "solid",
        borderWidth: 2,
        borderRadius: 5,
      }}
    >
      {eventInfo.timeText && <div className="fc-event-time">{eventInfo.timeText}</div>}
      <Tippy content={eventInfo.event.title}>
        <div className="fc-event-title">{eventInfo.event.title || <>&nbsp;</>}</div>
      </Tippy>
    </div>
  );
}
