export interface AARStatusType {
  id: number;
  display: string;
  bgColor: string;
  color: string;
  type: number;
  order: number;
  lmsLinkId: number;
  bcLinkVar: string;
  forType?: string;
}

interface AARStatusList {
  [key: string]: AARStatusType;
}

export const AARStatus: AARStatusList = {
  // default_Push_Back: {id: -2, display: "Push Back", bgColor: "", color: "", type: 0, order: 0, lmsLinkId: 6, bcLinkVar:'booked',forType:'appt'},
  // default_Rescheduled: {id: -1, display: "Rescheduled", bgColor: "", color: "", type: 0, order: 1, lmsLinkId: 6, bcLinkVar:'booked',forType:'appt'},
  default_Sold: {
    id: 2,
    display: "Sold",
    bgColor: "#1ec826",
    color: "#000",
    type: 1,
    order: 0,
    lmsLinkId: 4,
    bcLinkVar: "sold",
    forType: "appt",
  },
  default_Seen_Not_Sold: {
    id: 3,
    display: "Seen/Not Sold",
    bgColor: "#c6c81e",
    color: "#000",
    type: 1,
    order: 2,
    lmsLinkId: 2,
    bcLinkVar: "notsold",
    forType: "appt",
  },
  default_No_Show: {
    id: 4,
    display: "No Show",
    bgColor: "#b9650e",
    color: "#000",
    type: 0,
    order: 3,
    lmsLinkId: 7,
    bcLinkVar: "noshow",
    forType: "appt",
  },
  default_PNI: {
    id: 5,
    display: "PNI",
    bgColor: "#c81e1e",
    color: "#000",
    type: 0,
    order: 4,
    lmsLinkId: 3,
    bcLinkVar: "pni",
    forType: "appt",
  },
  default_Pushback: {
    id: 9,
    display: "Push Back",
    bgColor: "#aa82f0",
    color: "#000",
    type: 0,
    order: 4,
    lmsLinkId: 8,
    bcLinkVar: "callback",
    forType: "appt",
  },
  default_Odd: {
    id: 12,
    display: "Odd",
    bgColor: "#c81e1e",
    color: "#fff",
    type: 0,
    order: 5,
    lmsLinkId: 14,
    bcLinkVar: "odd",
    forType: "appt",
  },
  default_Sold_Former_Client: {
    id: 13,
    display: "Sold-Former Client",
    bgColor: "#1ec826",
    color: "#000",
    type: 1,
    order: 6,
    lmsLinkId: 4,
    bcLinkVar: "sold",
    forType: "appt",
  },
  default_Active_Client: {
    id: 14,
    display: "Active Client",
    bgColor: "#138419",
    color: "#fff",
    type: 1,
    order: 7,
    lmsLinkId: 4,
    bcLinkVar: "sold",
    forType: "appt",
  },
  default_Converted_FSR: {
    id: 15,
    display: "Converted FSR",
    bgColor: "#f68fe0",
    color: "#000",
    type: 0,
    order: 8,
    lmsLinkId: 15,
    bcLinkVar: "sold",
    forType: "appt",
  },

  special_default_Cancelled: {
    id: 0,
    display: "Cancelled",
    bgColor: "#8D1F1FA0",
    color: "#2d2d2d",
    type: 0,
    order: 2,
    lmsLinkId: 1,
    bcLinkVar: "",
    forType: "appt",
  },
  special_default_Active_Appt: {
    id: 1,
    display: "Active Appt",
    bgColor: "",
    color: "",
    type: 0,
    order: 0,
    lmsLinkId: 6,
    bcLinkVar: "booked",
    forType: "appt",
  },
  special_default_Seen_Followup: {
    id: 8,
    display: "Seen/Followup Sched",
    bgColor: "#68339e",
    color: "#fff",
    type: 0,
    order: 9,
    lmsLinkId: 2,
    bcLinkVar: "notsold",
    forType: "appt",
  },
  special_default_Followup: {
    id: 11,
    display: "Followup",
    bgColor: "#68339e",
    color: "#fff",
    type: 0,
    order: 0,
    lmsLinkId: 11,
    bcLinkVar: "notsold",
    forType: "appt",
  },

  availability: {
    id: 100,
    display: "Availability",
    bgColor: "#948802",
    color: "#fff",
    type: 0,
    order: 12,
    lmsLinkId: 0,
    bcLinkVar: "",
  },

  special_PDR: {
    id: 101,
    display: "PDR",
    bgColor: "#049cb3",
    color: "#000",
    type: 0,
    order: 0,
    lmsLinkId: 0,
    bcLinkVar: "sold",
  },
  PDR_Complete: {
    id: 103,
    display: "PDR Complete",
    bgColor: "#ffa800",
    color: "#000",
    type: 0,
    order: 1,
    lmsLinkId: 201,
    bcLinkVar: "sold",
  },
  PDR_Other: {
    id: 105,
    display: "PDR Other",
    bgColor: "#c6c81e",
    color: "#000",
    type: 0,
    order: 2,
    lmsLinkId: 202,
    bcLinkVar: "sold",
  },

  special_debt_Cancelled: {
    id: 200,
    display: "Cancelled DC",
    bgColor: "",
    color: "",
    type: 2,
    order: 0,
    lmsLinkId: 102,
    bcLinkVar: "",
    forType: "debt",
  },
  special_debt_Active_Appt: {
    id: 201,
    display: "Active DC Appt",
    bgColor: "",
    color: "",
    type: 2,
    order: 1,
    lmsLinkId: 106,
    bcLinkVar: "booked",
    forType: "debt",
  },
  debt_Enrolled: {
    id: 202,
    display: "DC Enrolled",
    bgColor: "#1ec826",
    color: "#000",
    type: 2,
    order: 3,
    lmsLinkId: 103,
    bcLinkVar: "sold",
    forType: "debt",
  },
  debt_Seen_Not_Sold: {
    id: 203,
    display: "DC Seen/Not Sold",
    bgColor: "#c6c81e",
    color: "#000",
    type: 2,
    order: 4,
    lmsLinkId: 104,
    bcLinkVar: "notsold",
    forType: "debt",
  },
  debt_PNI: {
    id: 204,
    display: "DC Not Interested",
    bgColor: "#c81e1e",
    color: "#000",
    type: 2,
    order: 5,
    lmsLinkId: 105,
    bcLinkVar: "pni",
    forType: "debt",
  },
  debt_No_Show: {
    id: 205,
    display: "DC No Show",
    bgColor: "#b9650e",
    color: "#000",
    type: 2,
    order: 7,
    lmsLinkId: 107,
    bcLinkVar: "noshow",
    forType: "debt",
  },
};

export function getAARStatus(aarStatus: number): AARStatusType {
  for (let key of Object.keys(AARStatus)) {
    if (String(AARStatus[key].id) === String(aarStatus)) {
      return AARStatus[key];
    }
  }
  return {
    id: aarStatus,
    display: "Unknown",
    bgColor: "",
    color: "",
    type: 0,
    order: 0,
    lmsLinkId: 0,
    bcLinkVar: "",
  };
}

export function getAllAARStatusList(prefix: string = ""): AARStatusType[] {
  let arr: AARStatusType[] = [];
  for (let key of Object.keys(AARStatus).filter((key) => key.startsWith(prefix))) {
    arr.push(AARStatus[key]);
  }
  return arr.sort((a, b) => (a.order > b.order ? 1 : -1));
}

export function getAARResultsByType(apptType: number) {
  switch (apptType) {
    case 11:
      return getAllAARStatusList("PDR");
    case 2:
    case 4:
    case 5:
      return getAllAARStatusList("default");
    case 13:
    case 15:
      return getAllAARStatusList("debt");
    default:
      return [];
  }
}

// export function getAARColor(aarStatus) {}
