import { ModalMaker } from "../../../components/ModalMaker/ModalMaker";
import MDButton from "../../../components/MDButton";
import React, { useEffect, useState } from "react";
import { getData } from "../../../api/api";
import { parseBackendResponse } from "../../../shared/util";
import MDBox from "components/MDBox";
import { VideoPlayer } from "../../../components/VideoPlayer/VideoPlayer";

interface ModalProps {
  camera_id: string;
  dated: number;
}

type ModalCallback = (data: any) => void;

export const useCameraMediaPlayer = () => {
  return {
    show: (options: ModalProps, callback?: ModalCallback) => {
      if (translationLayer.show) {
        translationLayer.callback = callback;
        translationLayer.show(options);
      }
    },
  };
};

const translationLayer: {
  show: (options: ModalProps) => void | null;
  callback: ModalCallback | null;
} = {
  show: null,
  callback: null,
};

export function CameraMediaPlayer(props: {}) {
  const [url, setURL] = useState<string | null>(null);

  useEffect(() => {
    translationLayer["show"] = (options: ModalProps) => {
      if (options.camera_id && options.dated) {
        getData(`camera/video/${options.camera_id}/${options.dated}`)
          .then(parseBackendResponse)
          .then((data: any) => {
            console.log(data);
            setURL(data.url);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    };
    return () => {
      translationLayer["show"] = null;
      translationLayer["callback"] = null;
    };
  }, [setURL]);

  const resetSearch = () => {
    setURL(null);
  };

  return (
    <ModalMaker
      title={`Find User`}
      open={!!url}
      fullWidth
      actions={
        <>
          <MDBox
            p={0}
            mx={0}
            height={35}
            display={"flex"}
            alignContent={"baseline"}
            justifyContent={"space-between"}
          >
            <MDButton
              color={"primary"}
              onClick={() => {
                window.open(url, "_blank");
              }}
            >
              Download
            </MDButton>
            <MDButton color={"info"} onClick={resetSearch}>
              Close
            </MDButton>
          </MDBox>
        </>
      }
    >
      {/*<MDBox*/}
      {/*  p={2}*/}
      {/*  mx={12}*/}
      {/*  my={-5}*/}
      {/*  display="flex"*/}
      {/*  justifyContent="left"*/}
      {/*  position={"fixed"}*/}
      {/*  bgColor={"white"}*/}
      {/*>*/}
      {url && <VideoPlayer url={url} />}
      {/*</MDBox>*/}
    </ModalMaker>
  );
}
