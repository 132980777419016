import { zPad } from "shared/util";

export function CameraButtonColor(status: string) {
  switch (status) {
    case "ready":
      return "info";
    case "recording":
      return "error";
    default:
      return "default";
  }
}

export function buttonDisable(status: string, connected: boolean) {
  if (!connected) return true;
  switch (status) {
    case "ready":
    case "recording":
      return false;
    default:
      return true;
  }
}

export function buttonText(status: string) {
  switch (status) {
    case "ready":
      return "Record";
    case "recording":
      return "Stop";
    default:
      return "";
  }
}

export function statusText(status: string) {
  switch (status) {
    case "ready":
      return "Idle";
    case "recording":
      return "Recording...";
    case "disconnected":
      return "Offline";
    default:
      return "Offline...";
  }
}

export function displayDate(dated: number) {
  if (!dated) return "";
  if (typeof dated !== "number") return dated;
  const dateObject = new Date(dated);
  const date = [
    dateObject.getFullYear(),
    zPad((dateObject.getMonth() + 1).toString(), 2),
    zPad(dateObject.getDate().toString(), 2),
  ].join("-");
  const time = [
    zPad(dateObject.getHours().toString(), 2),
    zPad((dateObject.getMinutes() + 1).toString(), 2),
    zPad(dateObject.getSeconds().toString(), 2),
  ].join(":");
  return date + " " + time;
}

export function isAvailable(status: string) {
  return status === "ready" || status === "recording";
}

export function isReady(status: string) {
  return status === "ready";
}

export function isRecording(status: string) {
  return status === "recording";
}
